import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageBecomeASitter = (props) => (
  <Template>
    <Helmet>
      <title>Become a sitter - {props.data.site.siteMetadata.title}</title>
    </Helmet>
    <div className={style.heroContainer}>
      <h1>Become a dog sitter on doggos.com</h1>
      <p className={style.subline}>
        More than 50,000 visitors browse doggos.com every month
      </p>
      <div className={style.buttonContainer}>
        <Link to='/sitter-application/' className={style.buttonPrimary}>
          Become a sitter
        </Link>
      </div>
    </div>

    <div className={style.toolsToGrow}>
      <div className={style.copy}>
        <h2>Apply for a dog sitter account</h2>
        <p>
          With a dog sitter account, you get access to a profile page and free
          promotion in our search results, our super-customizable calendar and
          rates dashboard, a live message inbox, and much more!
        </p>
      </div>
      <div>
        <StaticImage
          src={'./dog-sitter-1.png'}
          alt='Image of a young woman sitting on a sofa petting a dog.'
          className={style.coverPhoto}
        />
      </div>
    </div>

    <div className={style.howItWorks}>
      <h2>How it works</h2>
      <div className={style.media}>
        <div>
          <h3>1. Apply for a dog sitter account</h3>
          <p>
            Start by submitting an{' '}
            <Link to={'/become-a-sitter/'}> application</Link>. Someone from
            doggos.com will reach out to you within 48 hours with next steps to
            set up your free profile and get full access to your account.
          </p>
        </div>
        <div>
          <StaticImage
            src={'./1.png'}
            alt='Illustrated image of hands holding a mobile phone'
            className={style.backgroundImage}
          />
        </div>
      </div>

      <div className={style.media}>
        <div>
          <StaticImage
            src={'./2.png'}
            alt='Illustrated image of woman and her dog'
            className={style.backgroundImage}
          />
        </div>
        <div>
          <h3>
            2. Accept <span>(and deny)</span> bookings{' '}
          </h3>
          <p>
            Once you have an account, you can customize your page, add your
            rates and availability preferences, and whenever you're ready, "Go
            live" and start accepting booking requests. No separate app download
            required!
          </p>
        </div>
      </div>

      <div className={style.media}>
        <div>
          <h3>3. Get paid with 0 fees</h3>
          <p>
            <strong>
              We're the only platform that has 0 fees for dog sitters.
            </strong>{' '}
            <br />
            After you complete a booking, payments appear in your account with
            no deductions in as soon as 24 hours.
          </p>
          <div className={style.buttonContainer}>
            <Link to='/sitter-application/' className={style.button}>
              Become a dog sitter
            </Link>
          </div>
        </div>
        <StaticImage
          src={'./3.png'}
          alt='Illustrated image of hands holding a mobile phone.'
          className={style.backgroundImage}
        />
      </div>
    </div>
  </Template>
)

export default PageBecomeASitter
